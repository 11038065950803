import './styles.css';

import PartySocket from 'partysocket';

declare const PARTYKIT_HOST: string;

let pingInterval: ReturnType<typeof setInterval>;

// Let's append all the messages we get into this DOM element
const output = document.getElementById('app') as HTMLDivElement;

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: 'my-new-room',
});

const dimensions = {
  x: innerWidth,
  y: innerHeight,
};

window.addEventListener('resize', (event) => {
  dimensions.x = window.innerWidth;
  dimensions.y = window.innerHeight;

  console.log(dimensions);
});

function randomHexColor() {
  var r = Math.floor(Math.random() * 256);
  var g = Math.floor(Math.random() * 256);
  var b = Math.floor(Math.random() * 256);
  return '#' + r.toString(16) + g.toString(16) + b.toString(16);
}

function updateCursor(id: string, x: number, y: number) {
  const cursor = document.getElementById(id);

  if (!cursor) {
    const container = document.createElement('div');
    const cursor = document.createElement('div');
    cursor.id = id;
    cursor.style.width = '5px';
    cursor.style.height = '5px';
    cursor.style.background = randomHexColor();
    cursor.style.position = 'absolute';
    cursor.style.left = (x * window.innerWidth).toString() + 'px';
    cursor.style.top = (y * window.innerHeight).toString() + 'px';
    cursor.style.border = '1px solid white';
    container.appendChild(cursor);
    output.appendChild(container);
  } else {
    cursor.style.left = (x * window.innerWidth).toString() + 'px';
    cursor.style.top = (y * window.innerHeight).toString() + 'px';
  }
}

// You can even start sending messages before the connection is open!
conn.addEventListener('message', (event) => {
  const payload = JSON.parse(event.data);

  updateCursor(payload.id, payload.cursorPos.x, payload.cursorPos.y);
});

conn.addEventListener('close', (event) => {
  const cursor = document.getElementById(conn.id);
  cursor?.parentElement?.parentElement?.removeChild(cursor.parentElement);
});

const cursorPos = {
  x: 0,
  y: 0,
};

onmousemove = function handleMouseMove(event: MouseEvent) {
  cursorPos.x = event.clientX / dimensions.x;
  cursorPos.y = event.clientY / dimensions.y;

  const payload = JSON.stringify({ id: conn.id, cursorPos });
  conn.send(payload);
};
